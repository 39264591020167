import React from "react";

import { PortableText } from "@portabletext/react";
import { graphql, Link } from "gatsby";
import SanityImage from "gatsby-plugin-sanity-image";
import PropTypes from "prop-types";

import ServiceLogo from "../../components/ServiceLogo/ServiceLogo";
import Umbrella from "../../components/Umbrella/Umbrella";
import UmbrellaContent from "../../components/UmbrellaContent/UmbrellaContent";
import { ScrollDownIcon } from "../../icons";
import AllComponents from "../content/AllComponents";
import Header from "../layouts/Header";
import MainLayout from "../layouts/MainLayout";
import Seo from "../Seo/Seo";

const AllComponentsPage = ({ data: { page } }) => {
  const bgImage = `linear-gradient(0deg, rgba(18,18,30,1) 0%, rgba(18,18,30,.32) 49%, rgba(18,18,30,1) 100%), url(${page.headerimage?.asset?.url})`;

  return (
    <MainLayout
      breadcrumb={null}
      header={(
        <Header
          variant="blank"
          theme="dark"
          bgImageUrl={bgImage}
          headline={page.headline}
          text={<PortableText value={page._rawDescription} />}
          logo={page.icon && (
            <SanityImage
              {...page.icon}
              alt={page.title}
            />
          )}
          umbrella={
            page.services
              ? (
                <UmbrellaContent umbrella={<Umbrella label="What we did" color="white" />}>
                  {page.services.map((service) => (
                    <ServiceLogo
                      key={service.id}
                      variant={service.variant}
                      size="small"
                    />
                  ))}
                </UmbrellaContent>
              )
              : (
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-x-30 gap-y-15 w-full">
                  <Umbrella
                    animate="hover"
                    label="Our mission"
                    color="white"
                    icons={<ScrollDownIcon />}
                    to="#our-mission"
                    title="Our Mission"
                    as={Link}
                  />
                  <Umbrella
                    animate="hover"
                    label="Our Offices"
                    color="white"
                    icons={<ScrollDownIcon />}
                    to="#our-offices"
                    title="Our offices"
                    as={Link}
                  />
                  <Umbrella
                    animate="hover"
                    label="Careers"
                    color="white"
                    icons={<ScrollDownIcon />}
                    to="#careers"
                    title="Careers"
                    as={Link}
                  />
                </div>
              )
        }
        />
      )}
    >
      {page.contentElements && (
        <AllComponents elements={page.contentElements} />
      )}
    </MainLayout>
  );
};

export const query = graphql`
   query ($id: String!) {
    page: sanityAllComponentsPage(id: {eq: $id}) {
      id
      Metadata {
        ...MetaData
      }
      title
      variant
      slug {
        current
      }
      headerimage {
        asset {
          url
        }
      }
      headline
      _rawDescription
      contentElements {
        ...SectionHeader
        ...ServiceCardList
        ...OfficeCardList
        ...ContentCardList
        ...SolutionCardList
        ...JobCardList
        ...CtaCard
        ...ContentSection
        ...ContactSection 
        ...ContentModuleEmbed
      }
    }
 }
`;

AllComponentsPage.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

const seo = ({ data }) => (
  <Seo data={data.page?.Metadata} />
);

seo.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export const Head = seo;

export default AllComponentsPage;
